@charset "UTF-8";
@font-face {
  font-family: "Amble";
  src: url("../fonts/Amble-Light-webfont.eot");
  src: url("../fonts/Amble-Light-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/Amble-Light-webfont.woff") format("woff"), url("../fonts/Amble-Light-webfont.ttf") format("truetype"), url("../fonts/Amble-Light-webfont.svg#amblelight") format("svg");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "Amble";
  src: url("../fonts/Amble-Regular-webfont.eot");
  src: url("../styleguide/fonts/Amble-Regular-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/Amble-Regular-webfont.woff") format("woff"), url("../fonts/Amble-Regular-webfont.ttf") format("truetype"), url("../fonts/Amble-Regular-webfont.svg#ambleregular") format("svg");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Amble";
  src: url("../fonts/Amble-Bold-webfont.eot");
  src: url("../fonts/Amble-Bold-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/Amble-Bold-webfont.woff") format("woff"), url("../fonts/Amble-Bold-webfont.ttf") format("truetype"), url("../fonts/Amble-Bold-webfont.svg#amblebold") format("svg");
  font-weight: 700;
  font-style: normal;
}
html {
  font-size: 75%;
  line-height: 1.33333em;
}

*::-moz-selection {
  color: #ea6307;
  background-color: rgba(225, 225, 225, 0.5);
}
*::selection {
  color: #ea6307;
  background-color: rgba(225, 225, 225, 0.5);
}

html, body {
  background: white;
  color: black;
}

body {
  font-family: "Amble", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-style: normal;
  line-height: inherit;
}

strong, b {
  font-weight: 700;
}

a {
  color: inherit;
  text-decoration: none;
  -webkit-tap-highlight-color: transparent;
}
a:visited, a:active, a:hover {
  color: inherit;
}
a.active {
  cursor: default;
}
a:focus {
  outline: none;
}
a[href^='tel:'] {
  text-decoration: none !important;
  cursor: default;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

p {
  margin: 0;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
  font-weight: 400;
  font-size: 1em;
}

hr {
  border: none;
  background: black;
  height: 3px;
  width: 5.33333em;
  margin: 0 auto;
  margin-top: 2.66667em;
  margin-bottom: 2.66667em;
}

.text {
  font-weight: 300;
  letter-spacing: 0.025em;
}
.text p {
  font-size: 1.25em;
  line-height: 1.33333em;
  margin-bottom: 1.06667em;
}
.text h4 {
  font-size: 1.5em;
  line-height: 1.33333em;
  margin-bottom: 0.22222em;
  color: #747474;
  text-align: center;
}
.text h5 {
  font-weight: 700;
}
.text h5, .text h6 {
  text-transform: uppercase;
  letter-spacing: 0.05em;
  font-size: 1.25em;
  line-height: 1.33333em;
  margin-bottom: 0.26667em;
}
.text blockquote {
  border-left: 3px solid #ea6307;
  margin: 0;
  padding: 8px 16px;
  margin-bottom: 1.33333em;
  font-style: italic;
  color: #747474;
}
.text blockquote hr {
  background-color: #747474;
}
.text ul {
  list-style-type: disc;
}
.text ul, .text ol {
  padding: 0;
  margin-left: 1.33333em;
  margin-top: 0em;
  margin-bottom: 1em;
}
.text ul ul, .text ul ol, .text ol ul, .text ol ol {
  margin-bottom: 0em;
}
.text li {
  font-size: 1.25em;
  line-height: 1.33333em;
  margin-bottom: 0.26667em;
}
.text li li {
  font-size: 1em;
  line-height: 1.33333em;
  margin-bottom: 0em;
}
.text a {
  border-bottom: 3px solid #e1e1e1;
}
.cke_editable .text a:hover, .text a:hover, .text a:focus {
  color: #ea6307;
  border-bottom-color: #ea6307;
}

.cke_editable {
  padding: 1.33333em;
}

@media only screen and (min-width: 512px) {
  .body-text {
    font-size: 110%;
  }
}
*, *:before, *:after, input[type='search'] {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

body, html {
  position: relative;
  height: 100%;
}

body {
  margin: 0;
  width: 100%;
  min-width: 300px;
}

.load-alert {
  z-index: 10000;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  text-align: center;
  background: red;
  color: white;
  padding: 16px 48px;
  font-size: 1.5em;
  line-height: 1.11111em;
}
.load-alert a {
  text-decoration: underline;
}

.main-container {
  padding-top: 5.33333em;
  padding-bottom: 22.66667em;
  min-height: 100%;
}
.main-container.no-scroll {
  height: 100%;
  overflow: hidden;
}

.main-content {
  position: relative;
  margin: 0 auto;
  width: 100%;
  max-width: 106.66667em;
  padding: 0 1.33333em;
}

.container {
  position: relative;
  margin: 0 auto;
  padding: 2.66667em 0;
  width: 100%;
  max-width: 64em;
}

@media only screen and (min-width: 512px) {
  .main-container {
    padding-top: 5em;
    padding-bottom: 29.33333em;
  }
}
@media only screen and (min-width: 768px) {
  .main-container {
    padding-top: 6em;
  }
  .main-container.no-scroll {
    height: auto;
    overflow: auto;
  }
}
.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  font-family: "proxima-nova", Helvetica, Arial, sans-serif;
  z-index: 1000;
  min-width: 300px;
}

.inner-header {
  position: relative;
  padding: 0.66667em 1.33333em 0 1.33333em;
  margin: 0 auto;
  max-width: 106.66667em;
  background: white;
  -moz-box-shadow: 0 3px 0 rgba(242, 143, 0, 0.25);
  -webkit-box-shadow: 0 3px 0 rgba(242, 143, 0, 0.25);
  box-shadow: 0 3px 0 rgba(242, 143, 0, 0.25);
  height: 5.33333em;
}
.inner-header:before, .inner-header:after {
  content: " ";
  display: table;
}
.inner-header:after {
  clear: both;
}

.logo {
  float: left;
}
.logo img {
  display: inline-block;
  vertical-align: middle;
  width: auto;
}
.logo .logo-castor {
  height: 4em;
  position: absolute;
  left: 1em;
  bottom: -1.33333em;
}
.logo .logo-baseline {
  height: 2em;
  margin-right: 0.66667em;
}

.langs {
  float: right;
}
.langs li {
  display: inline-block;
  margin-left: 0.66667em;
}
.langs a, .langs span {
  display: block;
  text-transform: uppercase;
  font-size: 1.33333em;
  line-height: 2em;
  color: #80715c;
}
.langs a {
  font-weight: 300;
}
.langs a:hover, .langs a:focus, .langs a:active {
  color: #f28f00;
}
.langs span {
  font-weight: 700;
}

.header-nav {
  position: absolute;
  bottom: -3px;
  right: 1.33333em;
}
.header-nav li {
  display: inline-block;
  margin-left: 1.33333em;
  line-height: 2em;
}
.header-nav li:first-child {
  display: none;
}
.header-nav li.active a {
  color: #f28f00;
  border-bottom-color: #f28f00;
}
.header-nav a {
  display: block;
  text-transform: uppercase;
  font-size: 1.25em;
  line-height: 1.6em;
  letter-spacing: 0.1em;
  color: #747474;
  font-weight: 300;
  border-bottom: 3px solid transparent;
}
.header-nav a:hover, .header-nav a:focus, .header-nav a:active {
  color: #80715c;
  border-bottom-color: #80715c;
}

.header__socials {
  display: none;
}

@media only screen and (min-width: 384px) {
  .header-nav li:first-child {
    display: inline-block;
  }
}
@media only screen and (min-width: 512px) {
  .inner-header {
    height: 5em;
  }

  .logo .logo-castor {
    position: relative;
    left: 0;
    bottom: auto;
    height: 5.33333em;
  }
}
@media only screen and (min-width: 768px) {
  .inner-header {
    padding-top: 1.33333em;
    height: 6em;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  .logo {
    position: absolute;
    right: 50%;
    margin-right: -2.66667em;
  }
  .logo .logo-baseline {
    height: 2.66667em;
    margin-right: 1.33333em;
  }

  .header__socials {
    display: block;
    position: absolute;
    top: 1.66667em;
    left: 1.33333em;
  }
  .header__socials:before, .header__socials:after {
    content: " ";
    display: table;
  }
  .header__socials:after {
    clear: both;
  }
  .header__socials li {
    float: left;
    margin-left: 0.66667em;
  }

  .header__social {
    display: block;
    text-indent: -119988px;
    overflow: hidden;
    text-align: left;
    text-transform: capitalize;
    background-color: #f28f00;
    border-radius: 50%;
  }
  .header__social:hover, .header__social:focus {
    background-color: #80715c;
  }
  .header__social--fb {
    background-image: url("../img/sprites.png?=1741684382290");
    background-position: -66px -114px;
    background-repeat: no-repeat;
    width: 32px;
    height: 32px;
  }
}
@media only screen and (min-width: 768px) and (min-resolution: 2dppx), only screen and (min-width: 768px) and (-webkit-min-device-pixel-ratio: 2) {
  .header__social--fb {
    background-image: url("../img/sprites@2x.png?=1741684382290");
    background-position: -66px -114px;
    background-repeat: no-repeat;
    background-size: 218px 212px;
  }
}
@media only screen and (min-width: 768px) {
  .header__social--pin {
    background-image: url("../img/sprites.png?=1741684382290");
    background-position: -187px -68px;
    background-repeat: no-repeat;
    width: 32px;
    height: 32px;
  }
}
@media only screen and (min-width: 768px) and (min-resolution: 2dppx), only screen and (min-width: 768px) and (-webkit-min-device-pixel-ratio: 2) {
  .header__social--pin {
    background-image: url("../img/sprites@2x.png?=1741684382290");
    background-position: -186px -68px;
    background-repeat: no-repeat;
    background-size: 218px 212px;
  }
}
@media only screen and (min-width: 768px) {
  .header__social--insta {
    background-image: url("../img/sprites.png?=1741684382290");
    background-position: -134px -114px;
    background-repeat: no-repeat;
    width: 32px;
    height: 32px;
  }
}
@media only screen and (min-width: 768px) and (min-resolution: 2dppx), only screen and (min-width: 768px) and (-webkit-min-device-pixel-ratio: 2) {
  .header__social--insta {
    background-image: url("../img/sprites@2x.png?=1741684382290");
    background-position: -134px -114px;
    background-repeat: no-repeat;
    background-size: 218px 212px;
  }
}
@media only screen and (min-width: 768px) {
  .header__social--youtube {
    background-image: url("../img/sprites.png?=1741684382290");
    background-position: 0px -180px;
    background-repeat: no-repeat;
    width: 32px;
    height: 32px;
  }
}
@media only screen and (min-width: 768px) and (min-resolution: 2dppx), only screen and (min-width: 768px) and (-webkit-min-device-pixel-ratio: 2) {
  .header__social--youtube {
    background-image: url("../img/sprites@2x.png?=1741684382290");
    background-position: 0px -180px;
    background-repeat: no-repeat;
    background-size: 218px 212px;
  }
}

@media only screen and (min-width: 768px) {
  .langs {
    margin-top: 0.33333em;
  }
  .langs li {
    margin-left: 0.66667em;
  }
  .langs a, .langs span {
    font-size: 1.08333em;
    line-height: 2.46154em;
  }

  .header-nav li {
    margin-left: 1.33333em;
  }
  .header-nav a {
    font-size: 1.08333em;
    line-height: 1.84615em;
  }
}
.footer {
  position: relative;
  color: #f6f3ee;
  background: #80715c;
  background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuNSIgeTE9IjAuMCIgeDI9IjAuNSIgeTI9IjEuMCI+PHN0b3Agb2Zmc2V0PSI3NSUiIHN0b3AtY29sb3I9IiM4ZjdlNjciLz48c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiM4MDcxNWMiLz48L2xpbmVhckdyYWRpZW50PjwvZGVmcz48cmVjdCB4PSIwIiB5PSIwIiB3aWR0aD0iMTAwJSIgaGVpZ2h0PSIxMDAlIiBmaWxsPSJ1cmwoI2dyYWQpIiAvPjwvc3ZnPiA=');
  background-size: 100%;
  background-image: -webkit-gradient(linear, 50% 0%, 50% 100%, color-stop(75%, #8f7e67), color-stop(100%, #80715c));
  background-image: -moz-linear-gradient(#8f7e67 75%, #80715c);
  background-image: -webkit-linear-gradient(#8f7e67 75%, #80715c);
  background-image: linear-gradient(#8f7e67 75%, #80715c);
  font-family: "proxima-nova", Helvetica, Arial, sans-serif;
  margin-top: -22.66667em;
}

.footer-nav {
  text-align: center;
  position: relative;
  padding-top: 5.33333em;
  padding-bottom: 6.66667em;
}
.footer-nav .logo-castor {
  display: block;
  position: absolute;
  top: -1.33333em;
  margin-left: -2.66667em;
  left: 50%;
}
.footer-nav .logo-castor img {
  display: block;
  height: 5.33333em;
  width: 5.33333em;
}
.footer-nav .logo-baseline {
  display: inline-block;
  height: 2em;
  width: auto;
}
.footer-nav a.link {
  border-bottom: 1px solid #f6f3ee;
  margin-bottom: 2px;
}
.footer-nav a.link.ico {
  position: relative;
}
.footer-nav a.link.ico:before {
  content: "";
  display: block;
  position: absolute;
  top: -8px;
  left: -28px;
}
.footer-nav a.link.fb:before {
  background-image: url("../img/sprites.png?=1741684382290");
  background-position: -66px -114px;
  background-repeat: no-repeat;
  width: 32px;
  height: 32px;
}
@media only screen and (min-resolution: 2dppx), (-webkit-min-device-pixel-ratio: 2) {
  .footer-nav a.link.fb:before {
    background-image: url("../img/sprites@2x.png?=1741684382290");
    background-position: -66px -114px;
    background-repeat: no-repeat;
    background-size: 218px 212px;
  }
}
.footer-nav a.link.pin:before {
  background-image: url("../img/sprites.png?=1741684382290");
  background-position: -187px -68px;
  background-repeat: no-repeat;
  width: 32px;
  height: 32px;
}
@media only screen and (min-resolution: 2dppx), (-webkit-min-device-pixel-ratio: 2) {
  .footer-nav a.link.pin:before {
    background-image: url("../img/sprites@2x.png?=1741684382290");
    background-position: -186px -68px;
    background-repeat: no-repeat;
    background-size: 218px 212px;
  }
}
.footer-nav a.link.insta:before {
  background-image: url("../img/sprites.png?=1741684382290");
  background-position: -134px -114px;
  background-repeat: no-repeat;
  width: 32px;
  height: 32px;
}
@media only screen and (min-resolution: 2dppx), (-webkit-min-device-pixel-ratio: 2) {
  .footer-nav a.link.insta:before {
    background-image: url("../img/sprites@2x.png?=1741684382290");
    background-position: -134px -114px;
    background-repeat: no-repeat;
    background-size: 218px 212px;
  }
}
.footer-nav a.link.youtube:before {
  background-image: url("../img/sprites.png?=1741684382290");
  background-position: 0px -180px;
  background-repeat: no-repeat;
  width: 32px;
  height: 32px;
}
@media only screen and (min-resolution: 2dppx), (-webkit-min-device-pixel-ratio: 2) {
  .footer-nav a.link.youtube:before {
    background-image: url("../img/sprites@2x.png?=1741684382290");
    background-position: 0px -180px;
    background-repeat: no-repeat;
    background-size: 218px 212px;
  }
}
.footer-nav a.link.mail:before {
  background-image: url("../img/sprites.png?=1741684382290");
  background-position: -187px -34px;
  background-repeat: no-repeat;
  width: 32px;
  height: 32px;
}
@media only screen and (min-resolution: 2dppx), (-webkit-min-device-pixel-ratio: 2) {
  .footer-nav a.link.mail:before {
    background-image: url("../img/sprites@2x.png?=1741684382290");
    background-position: -186px -34px;
    background-repeat: no-repeat;
    background-size: 218px 212px;
  }
}

.footer-infos {
  padding: 0 1.33333em;
}
.footer-infos h1 {
  font-weight: 700;
  font-size: 1.33333em;
  line-height: 1.25em;
  margin-bottom: 1em;
}
.footer-infos p {
  margin-top: 0.57143em;
  font-size: 1.16667em;
  line-height: 1.42857em;
  font-weight: 300;
}
.footer-infos a {
  display: inline-block;
  margin-bottom: 3px;
}
.footer-infos a:hover, .footer-infos a:focus, .footer-infos a:active {
  border-bottom: 3px solid white;
  color: white;
  margin-bottom: 0;
}

.footer-menu {
  margin-top: 1.33333em;
}
.footer-menu a {
  display: inline-block;
  font-size: 1.16667em;
  line-height: 1.14286em;
  padding-top: 0.28571em;
  margin-bottom: 3px;
}
.footer-menu a:hover, .footer-menu a:focus {
  color: white;
  border-bottom: 3px solid white;
  margin-bottom: 0;
}
.footer-menu > li {
  padding: 0 1.33333em 0.66667em;
}
.footer-menu > li:first-child {
  display: none;
}
.footer-menu h2 {
  text-transform: uppercase;
  font-weight: 300;
  margin-bottom: 0.33333em;
}
.footer-menu h2 a {
  line-height: 2em;
}

.footer-credits {
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 2.66667em 1.33333em;
}

@media only screen and (min-width: 512px) {
  .footer {
    min-height: 29.33333em;
    margin-top: -29.33333em;
  }

  .footer-nav {
    text-align: left;
  }
  .footer-nav:before, .footer-nav:after {
    content: " ";
    display: table;
  }
  .footer-nav:after {
    clear: both;
  }
  .footer-nav a.link.ico {
    left: 16px;
  }

  .footer-infos {
    float: left;
    width: 33.3333%;
  }

  .footer-menu {
    float: right;
    width: 66.6666%;
    margin-top: 3.66667em;
    border-left: 1px solid #f6f3ee;
  }
  .footer-menu > li {
    width: 100%;
    padding: 0 0 0.66667em 1.33333em;
  }
  .footer-menu > li li {
    display: inline-block;
    vertical-align: top;
    margin-right: 1.33333em;
  }
}
@media only screen and (min-width: 768px) {
  .social-link {
    width: 50%;
    float: left;
  }

  .footer-menu > li {
    display: inline-block;
    vertical-align: top;
    width: 13.33333em;
  }
  .footer-menu > li li {
    display: block;
    margin-right: 0;
  }
}
figure {
  position: relative;
  width: 100%;
  margin: 0;
}
figure img {
  display: block;
  width: 100%;
  height: auto;
}
figure figcaption {
  margin-top: 0.33333em;
}

.video-frame {
  position: relative;
  padding: 0 0 56.25% 0;
  height: 0;
}
.video-frame object, .video-frame embed, .video-frame iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.gallery {
  margin-bottom: 1.33333em;
}
.gallery a {
  cursor: default;
}
.gallery figure {
  margin-bottom: 1.33333em;
}

.item-video {
  display: block;
  position: relative;
  cursor: pointer;
}
.item-video:after {
  content: "play";
  text-indent: -119988px;
  overflow: hidden;
  text-align: left;
  text-transform: capitalize;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -32px;
  margin-top: -32px;
  background-image: url("../img/sprites.png?=1741684382290");
  background-position: 0px -114px;
  background-repeat: no-repeat;
  width: 64px;
  height: 64px;
  background-color: rgba(180, 180, 180, 0.5);
  -moz-transition: background-color 0.2s ease-in-out;
  -o-transition: background-color 0.2s ease-in-out;
  -webkit-transition: background-color 0.2s ease-in-out;
  transition: background-color 0.2s ease-in-out;
  -moz-border-radius: 100%;
  -webkit-border-radius: 100%;
  border-radius: 100%;
}
@media only screen and (min-resolution: 2dppx), (-webkit-min-device-pixel-ratio: 2) {
  .item-video:after {
    background-image: url("../img/sprites@2x.png?=1741684382290");
    background-position: 0px -114px;
    background-repeat: no-repeat;
    background-size: 218px 212px;
  }
}
.item-video:hover:after, .item-video:focus:after, .item-video:active:after {
  background-color: #b4b4b4;
}

.section-title {
  text-align: center;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 2em;
  line-height: 1.16667em;
}

.btn {
  display: inline-block;
  border-width: 2px;
  border-style: solid;
  border-color: inherit;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  width: auto;
  max-width: 15.33333em;
  padding: 0.66667em 1.33333em;
  text-transform: uppercase;
  font-size: 1.08333em;
  line-height: 1.53846em;
  font-family: "Amble", Helvetica, Arial, sans-serif;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  margin: 0 auto;
}
.btn:hover, .btn:focus, .btn:active {
  color: white;
  background: #ea6307;
  border-color: #ea6307;
}
.btn.more-btn {
  position: relative;
  display: block;
  text-align: center;
  color: white;
  background: #ea6307;
  border-color: #ea6307;
}
.btn.more-btn:hover, .btn.more-btn:focus, .btn.more-btn:active {
  color: #ea6307;
  background: white;
}

.bgd-fig {
  background-position: center center;
  background-repeat: no-repeat;
  -moz-background-size: cover;
  -o-background-size: cover;
  -webkit-background-size: cover;
  background-size: cover;
}
.lt-ie9 .bgd-fig, .mdzr-no-backgroundsize .bgd-fig {
  -ms-behavior: url(/site/theme/assets/js/vendor/backgroundsize.min.htc);
}

.share {
  text-align: center;
  margin-top: 5.33333em;
  padding-bottom: 2.66667em;
}
.mdzr-no-js .share {
  display: none;
}
.mdzr-js .share {
  display: block;
}
.share li {
  display: inline-block;
  margin: 0.33333em;
}
.share span {
  display: none;
}
.share .fb {
  background-image: url("../img/sprites.png?=1741684382290");
  background-position: -66px -114px;
  background-repeat: no-repeat;
  width: 32px;
  height: 32px;
}
@media only screen and (min-resolution: 2dppx), (-webkit-min-device-pixel-ratio: 2) {
  .share .fb {
    background-image: url("../img/sprites@2x.png?=1741684382290");
    background-position: -66px -114px;
    background-repeat: no-repeat;
    background-size: 218px 212px;
  }
}
.share .pin {
  background-image: url("../img/sprites.png?=1741684382290");
  background-position: -187px -68px;
  background-repeat: no-repeat;
  width: 32px;
  height: 32px;
}
@media only screen and (min-resolution: 2dppx), (-webkit-min-device-pixel-ratio: 2) {
  .share .pin {
    background-image: url("../img/sprites@2x.png?=1741684382290");
    background-position: -186px -68px;
    background-repeat: no-repeat;
    background-size: 218px 212px;
  }
}
.share .tw {
  background-image: url("../img/sprites.png?=1741684382290");
  background-position: -187px -136px;
  background-repeat: no-repeat;
  width: 32px;
  height: 32px;
}
@media only screen and (min-resolution: 2dppx), (-webkit-min-device-pixel-ratio: 2) {
  .share .tw {
    background-image: url("../img/sprites@2x.png?=1741684382290");
    background-position: -186px -136px;
    background-repeat: no-repeat;
    background-size: 218px 212px;
  }
}
.share .pr {
  background-image: url("../img/sprites.png?=1741684382290");
  background-position: -187px -102px;
  background-repeat: no-repeat;
  width: 32px;
  height: 32px;
}
@media only screen and (min-resolution: 2dppx), (-webkit-min-device-pixel-ratio: 2) {
  .share .pr {
    background-image: url("../img/sprites@2x.png?=1741684382290");
    background-position: -186px -102px;
    background-repeat: no-repeat;
    background-size: 218px 212px;
  }
}
.share .ml {
  background-image: url("../img/sprites.png?=1741684382290");
  background-position: -187px -34px;
  background-repeat: no-repeat;
  width: 32px;
  height: 32px;
}
@media only screen and (min-resolution: 2dppx), (-webkit-min-device-pixel-ratio: 2) {
  .share .ml {
    background-image: url("../img/sprites@2x.png?=1741684382290");
    background-position: -186px -34px;
    background-repeat: no-repeat;
    background-size: 218px 212px;
  }
}
.share a {
  display: block;
  background-color: #80715c;
  -moz-transition: background-color 0.2s ease-in-out;
  -o-transition: background-color 0.2s ease-in-out;
  -webkit-transition: background-color 0.2s ease-in-out;
  transition: background-color 0.2s ease-in-out;
  -moz-border-radius: 100%;
  -webkit-border-radius: 100%;
  border-radius: 100%;
  cursor: pointer;
}
.share a:hover {
  background-color: #ea6307;
}

.pagination {
  margin: 1.33333em auto 2.66667em;
  text-align: center;
}
.pagination a, .pagination span {
  display: block;
}
.pagination ul {
  position: relative;
  display: inline-block;
  width: auto;
  max-width: 100%;
  padding: 1.33333em 2.66667em;
}
.pagination li {
  display: inline-block;
}
.pagination .page-arrow {
  display: block;
  position: absolute;
  top: 50%;
  margin-top: -2em;
}
.pagination .page-arrow a {
  text-indent: -119988px;
  overflow: hidden;
  text-align: left;
  text-transform: capitalize;
  -moz-transition: opacity 0.2s ease-in-out;
  -o-transition: opacity 0.2s ease-in-out;
  -webkit-transition: opacity 0.2s ease-in-out;
  transition: opacity 0.2s ease-in-out;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=25);
  opacity: 0.25;
}
.pagination .page-arrow a:hover, .pagination .page-arrow a:focus {
  filter: progid:DXImageTransform.Microsoft.Alpha(enabled=false);
  opacity: 1;
}
.pagination .page-arrow.prev {
  left: 0;
}
.pagination .page-arrow.prev a, .pagination .page-arrow.prev span {
  background-image: url("../img/sprites.png?=1741684382290");
  background-position: -155px -50px;
  background-repeat: no-repeat;
  width: 25px;
  height: 48px;
}
@media only screen and (min-resolution: 2dppx), (-webkit-min-device-pixel-ratio: 2) {
  .pagination .page-arrow.prev a, .pagination .page-arrow.prev span {
    background-image: url("../img/sprites@2x.png?=1741684382290");
    background-position: -154px -50px;
    background-repeat: no-repeat;
    background-size: 218px 212px;
  }
}
.pagination .page-arrow.next {
  right: 0;
}
.pagination .page-arrow.next a, .pagination .page-arrow.next span {
  background-image: url("../img/sprites.png?=1741684382290");
  background-position: -128px -50px;
  background-repeat: no-repeat;
  width: 25px;
  height: 48px;
}
@media only screen and (min-resolution: 2dppx), (-webkit-min-device-pixel-ratio: 2) {
  .pagination .page-arrow.next a, .pagination .page-arrow.next span {
    background-image: url("../img/sprites@2x.png?=1741684382290");
    background-position: -127px -50px;
    background-repeat: no-repeat;
    background-size: 218px 212px;
  }
}
.pagination .pager {
  display: inline-block;
  margin: 0.16667em;
}
.pagination .pager a, .pagination .pager span {
  width: 32px;
  height: 32px;
  -moz-border-radius: 100%;
  -webkit-border-radius: 100%;
  border-radius: 100%;
  font-size: 1.33333em;
  line-height: 2.25em;
  font-family: "proxima-nova", Helvetica, Arial, sans-serif;
  color: white;
}
.pagination .pager a {
  background-color: #f28f00;
}
.pagination .pager a:hover, .pagination .pager a:focus, .pagination .pager a:active {
  background-color: #ea6307;
}
.pagination .pager span {
  background-color: #80715c;
}

.products-index {
  text-align: center;
}

.filters {
  display: inline-block;
  text-align: center;
  margin-bottom: 1.33333em;
  padding-top: 4em;
  width: 100%;
  text-transform: uppercase;
}
.filters .filter-list {
  display: none;
}
.filters .filter-list a:hover, .filters .filter-list a:focus, .filters .filter-list a:active {
  color: #80715c;
  text-decoration: underline;
}
.filters .filter-list a.active {
  color: #ea6307;
}
.filters li {
  position: relative;
  display: inline-block;
  vertical-align: top;
  width: 100%;
}
.filters li li {
  width: auto;
  padding: 0.66667em 0.66667em 0 0.66667em;
}
.filters h2 {
  font-size: 1.33333em;
  line-height: 1.25em;
  letter-spacing: 0.1em;
  font-family: "proxima-nova", Helvetica, Arial, sans-serif;
  color: #b4b4b4;
}
.filters hr {
  margin-top: 1.33333em;
  margin-bottom: 1.33333em;
}

.filter-btn {
  position: absolute;
  top: 2em;
  left: 0;
  width: 100%;
  text-align: center;
  font-family: "proxima-nova", Helvetica, Arial, sans-serif;
}
.filter-btn a {
  position: relative;
  display: inline-block;
  letter-spacing: 0.1em;
  font-size: 1.33333em;
  line-height: 1.25em;
  padding-right: 24px;
}
.filter-btn a:after {
  content: "▼";
  position: absolute;
  top: 0;
  right: 0;
}
.filter-btn a:hover, .filter-btn a:focus, .filter-btn a:active {
  color: #ea6307;
}
.filter-btn a.active:after {
  content: "▲";
}

.carousel {
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
  opacity: 0;
}
.carousel.js-cycle-active, .carousel.js-cycle-inactive {
  filter: progid:DXImageTransform.Microsoft.Alpha(enabled=false);
  opacity: 1;
}

.banners {
  margin-left: -1.33333em;
  margin-right: -1.33333em;
}
.banners .banner {
  margin-top: 1.33333em;
}
.banners .banner .bgd-fig {
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=25);
  opacity: 0.25;
  -webkit-filter: grayscale(50%);
  filter: grayscale(50%);
}
.banners .banner:nth-child(even) article {
  background-color: #80715c;
}
.banners .banner:nth-child(odd) article {
  background-color: #f28f00;
}

.home-hero .banner .bgd-fig {
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=75);
  opacity: 0.75;
  -moz-transition: cubic-bezier(0.55, 0.085, 0.68, 0.53) 0.15s;
  -o-transition: cubic-bezier(0.55, 0.085, 0.68, 0.53) 0.15s;
  -webkit-transition: cubic-bezier(0.55, 0.085, 0.68, 0.53) 0.15s;
  transition: cubic-bezier(0.55, 0.085, 0.68, 0.53) 0.15s;
}
.home-hero .banner article {
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);
  background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjEuMCIgeTE9IjEuMCIgeDI9IjAuMCIgeTI9IjAuMCI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzgwNzE1YyIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iI2YyOGYwMCIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JhZCkiIC8+PC9zdmc+IA==');
  background-size: 100%;
  background-image: -moz-linear-gradient(135deg, #80715c, #f28f00);
  background-image: -webkit-linear-gradient(135deg, #80715c, #f28f00);
  background-image: linear-gradient(-45deg, #80715c, #f28f00);
}
.home-hero .banner article:hover .bgd-fig {
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=25);
  opacity: 0.25;
  -webkit-filter: brightness(70%);
  filter: brightness(70%);
}
.home-hero .banner article:hover .btn {
  background: #ea6307;
  border-color: #ea6307;
}

.banner {
  position: relative;
  width: 100%;
  height: 26.66667em;
}
.banner .bgd-fig {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=75);
  opacity: 0.75;
  -webkit-filter: brightness(70%);
  filter: brightness(70%);
}
.banner article {
  position: relative;
  text-align: center;
  color: white;
  background-color: #f28f00;
  height: 100%;
  font-family: "proxima-nova", Helvetica, Arial, sans-serif;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.banner .inner {
  position: relative;
  z-index: 10;
  left: 0;
  width: 100%;
  top: 50%;
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  padding: 1.33333em;
}
.banner h2 {
  text-transform: uppercase;
  font-size: 2em;
  line-height: 1em;
  margin-bottom: 0.33333em;
  font-family: "proxima-nova", Helvetica, Arial, sans-serif;
  font-weight: 700;
}
.banner p {
  font-family: "proxima-nova", Helvetica, Arial, sans-serif;
  font-size: 1.5em;
  line-height: 1.33333em;
  margin-bottom: 0.44444em;
}
.banner .btn {
  margin-top: 0.61538em;
  width: 16em;
}

@media only screen and (min-width: 384px) {
  .gallery {
    margin-left: -0.66667em;
    margin-right: -0.66667em;
    text-align: center;
  }
  .gallery figure {
    display: inline-block;
    vertical-align: top;
    width: 50%;
    padding: 0 0.66667em;
    font-size: 12px;
  }
  .gallery figcaption {
    display: none;
  }
  .gallery a {
    cursor: pointer;
  }

  .inner-gallery {
    font-size: 0;
  }
}
@media only screen and (min-width: 512px) {
  .gallery figure {
    width: 33.3333%;
  }
}
@media only screen and (min-width: 768px) {
  .home-hero .banner {
    height: 32em;
  }
  .home-hero .banner h2 {
    font-size: 3.83333em;
    line-height: 0.95652em;
    margin-bottom: 0.17391em;
  }
  .home-hero .banner p {
    font-size: 1.75em;
    line-height: 1.14286em;
    margin-bottom: 0.38095em;
  }

  .banners {
    margin-bottom: 1.33333em;
    padding: 0.66667em;
  }
  .banners .banner {
    padding: 0.66667em;
  }

  .gallery figure {
    width: 25%;
  }
}
@media only screen and (min-width: 1024px) {
  .home-hero .banner {
    height: 42.66667em;
  }
}
.article {
  padding-top: 2.66667em;
  padding-bottom: 2.66667em;
}

.page-hero {
  position: relative;
  margin-left: -1.33333em;
  margin-right: -1.33333em;
  height: 16em;
  width: auto;
}
.page-hero figure {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.figolu {
  position: absolute;
  width: 8em;
  height: 8em;
  top: 50%;
  left: 50%;
  margin-top: -4em;
  margin-left: -4em;
  -moz-border-radius: 100%;
  -webkit-border-radius: 100%;
  border-radius: 100%;
  overflow: hidden;
  z-index: 500;
}
.figolu.u-love {
  background-color: #ecc6dc;
}
.figolu.u-epic {
  background-color: #deecc6;
}
.figolu.u-serious {
  background-color: #c6ddec;
}
.figolu.u-sport {
  background-color: #c6ece2;
}
.figolu.u-dream {
  background-color: #d1c6ec;
}
.figolu img {
  width: 60%;
  margin: 20%;
}

.breadcrumb {
  margin-bottom: 0.66667em;
  font-size: 1em;
  line-height: 1.33333em;
  font-family: "proxima-nova", Helvetica, Arial, sans-serif;
  color: #f28f00;
}
.breadcrumb a {
  display: inline-block;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  font-weight: 300;
  border-bottom: 1px solid #f28f00;
}

.page-header {
  text-align: center;
  margin-bottom: 2.66667em;
}
.page-header h1, .page-header h2 {
  font-size: 2em;
  line-height: 1.16667em;
  margin-bottom: 0.66667em;
  text-transform: uppercase;
  letter-spacing: 0.1em;
}
.page-header h1 {
  font-weight: 700;
}
.page-header p {
  color: #747474;
  font-size: 1.33333em;
  line-height: 1.75em;
}
.page-header.hmp p {
  color: black;
}

.portrait {
  width: 16em;
  -moz-border-radius: 100%;
  -webkit-border-radius: 100%;
  border-radius: 100%;
  overflow: hidden;
  margin: 0 auto 2.66667em;
}

.files-list, .product-list, .gallery, .siblings {
  margin-top: 5.33333em;
  padding-bottom: 2.66667em;
}

.files-list {
  position: relative;
  padding: 2.66667em 2.66667em 2em;
  text-align: center;
  background: #f6f3ee;
  color: #80715c;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  border-radius: 4px;
}
.files-list ul {
  overflow: hidden;
}
.files-list:before {
  content: "downloads";
  display: block;
  position: absolute;
  top: 0;
  left: 50%;
  margin-top: -16px;
  margin-left: -16px;
  text-indent: -119988px;
  overflow: hidden;
  text-align: left;
  text-transform: capitalize;
  background-image: url("../img/sprites.png?=1741684382290");
  background-position: -100px -114px;
  background-repeat: no-repeat;
  width: 32px;
  height: 32px;
  -moz-border-radius: 100%;
  -webkit-border-radius: 100%;
  border-radius: 100%;
  background-color: #f6f3ee;
}
@media only screen and (min-resolution: 2dppx), (-webkit-min-device-pixel-ratio: 2) {
  .files-list:before {
    background-image: url("../img/sprites@2x.png?=1741684382290");
    background-position: -100px -114px;
    background-repeat: no-repeat;
    background-size: 218px 212px;
  }
}
.files-list li {
  line-height: 2em;
  margin-bottom: 0.66667em;
}
.files-list a {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  border-bottom: 1px solid #80715c;
  margin-bottom: 2px;
  font-family: "proxima-nova", Helvetica, Arial, sans-serif;
  font-size: 1.33333em;
  line-height: 1.5em;
}
.files-list a:hover, .files-list a:focus, .files-list a:active {
  color: #ea6307;
  border-bottom: 3px solid #ea6307;
  margin-bottom: 0;
}
.files-list span {
  display: block;
  font-size: 1em;
  line-height: 2em;
  font-weight: 300;
}

.argument {
  color: #80715c;
  font-family: "proxima-nova", Helvetica, Arial, sans-serif;
}
.argument figure {
  margin-bottom: 1.33333em;
}
.argument h5, .argument h6 {
  color: black;
}

.product-fig {
  margin-bottom: 1.33333em;
}
.product-fig img {
  max-width: 26.66667em;
  -moz-border-radius: 2.33333em;
  -webkit-border-radius: 2.33333em;
  border-radius: 2.33333em;
  overflow: hidden;
  margin: 0 auto;
}

.related {
  margin-top: 2.66667em;
}

@media only screen and (min-width: 384px) {
  .page-hero {
    height: 18.66667em;
  }
}
@media only screen and (min-width: 512px) {
  .breadcrumb {
    font-size: 1.08333em;
    line-height: 1.84615em;
  }

  .page-hero {
    height: 24em;
  }

  .figolu {
    width: 10.66667em;
    height: 10.66667em;
    margin-top: -5.33333em;
    margin-left: -5.33333em;
  }

  .argument:before, .argument:after {
    content: " ";
    display: table;
  }
  .argument:after {
    clear: both;
  }
  .argument figure, .argument .text {
    float: left;
    padding: 0.66667em;
  }
  .argument .text {
    width: 60%;
  }
  .argument figure {
    width: 40%;
    margin-bottom: 0em;
  }
  .argument:nth-child(even) figure {
    float: right;
    padding-right: 0;
  }
  .argument:nth-child(even) .text {
    padding-left: 0;
  }
  .argument:nth-child(odd) figure {
    padding-left: 0;
  }
  .argument:nth-child(odd) .text {
    padding-right: 0;
  }

  .product-detail:before, .product-detail:after {
    content: " ";
    display: table;
  }
  .product-detail:after {
    clear: both;
  }

  .product-fig {
    float: left;
    width: 40%;
    padding-right: 0.66667em;
  }

  .product-infos {
    float: right;
    width: 60%;
    padding-left: 0.66667em;
  }
}
@media only screen and (min-width: 768px) {
  .breadcrumb {
    margin-bottom: 1.33333em;
    font-size: 1.08333em;
    line-height: 1.84615em;
  }

  .page-hero {
    height: 32em;
  }

  .figolu {
    width: 13.33333em;
    height: 13.33333em;
    margin-top: -6.66667em;
    margin-left: -6.66667em;
  }

  .page-header p {
    font-size: 1.75em;
    line-height: 1.52381em;
  }

  .portrait {
    width: 21.33333em;
  }

  .argument figure, .argument .text {
    width: 50%;
  }

  .product-fig {
    width: 50%;
  }
  .product-fig img {
    margin: 0 0 0 auto;
  }

  .product-infos {
    width: 50%;
  }
}
@media only screen and (min-width: 1024px) {
  .page-hero {
    height: 37.33333em;
  }

  .figolu {
    width: 16em;
    height: 16em;
    margin-top: -8em;
    margin-left: -8em;
  }
}
.grid {
  text-align: center;
  font-size: 0;
  margin-left: -16px;
  margin-right: -16px;
}

.item {
  position: relative;
  display: inline-block;
  vertical-align: top;
  font-size: 12px;
  padding: 0 16px;
  color: #747474;
  width: 13.33333em;
  min-height: 13.33333em;
  margin-bottom: 2.66667em;
}
.item h3 {
  color: black;
  text-transform: uppercase;
  font-size: 1.25em;
  line-height: 1.33333em;
}
.item p {
  font-size: 1.25em;
  line-height: 1.33333em;
  font-family: "proxima-nova", Helvetica, Arial, sans-serif;
  margin-top: 0.53333em;
}
.item figure {
  position: relative;
  width: 9.33333em;
  height: 9.33333em;
  margin: 0 auto;
  margin-bottom: 1em;
}
.item img {
  -moz-border-radius: 100%;
  -webkit-border-radius: 100%;
  border-radius: 100%;
  overflow: hidden;
  width: 100%;
  height: 100%;
}
.item .btn {
  margin-top: 0.61538em;
}
.siblings .item p, .related .item p {
  display: none;
}
.siblings .item {
  font-size: 9px;
}
.related .item {
  width: 100%;
  min-height: 4em;
  margin-bottom: 1.33333em;
  padding: 0;
}
.related .item figure {
  width: 4em;
  height: 4em;
  margin: 0 0.66667em 0 0;
}
.related .item figure:before {
  display: none;
}
.related .item figure, .related .item h3 {
  display: inline-block;
  vertical-align: middle;
}
.item.status-1 figure:before {
  content: "new";
  position: absolute;
  display: block;
  top: 15%;
  right: 15%;
  color: white;
  background: #ea6307;
  font-family: "proxima-nova", Helvetica, Arial, sans-serif;
  text-transform: uppercase;
  text-align: center;
  font-size: 0.91667em;
  line-height: 3.63636em;
  -moz-border-radius: 100%;
  -webkit-border-radius: 100%;
  border-radius: 100%;
  font-weight: 300;
  width: 3.33333em;
  height: 3.33333em;
  margin-top: -1.66667em;
  margin-right: -1.66667em;
  z-index: 10;
}

.item-page {
  padding: 0 1.33333em;
  width: 21.33333em;
}

.item-product img {
  -moz-border-radius: 2.33333em;
  -webkit-border-radius: 2.33333em;
  border-radius: 2.33333em;
}

.item-universe figure {
  -moz-transition: background-color 0.2s ease-in-out;
  -o-transition: background-color 0.2s ease-in-out;
  -webkit-transition: background-color 0.2s ease-in-out;
  transition: background-color 0.2s ease-in-out;
  -moz-border-radius: 100%;
  -webkit-border-radius: 100%;
  border-radius: 100%;
  overflow: hidden;
}
.item-universe img {
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  border-radius: 0;
  width: 60%;
  height: 60%;
  margin: 20%;
}

.item-universe.u-love figure {
  background-color: #ecc6dc;
}
.item-universe.u-love a:hover figure, .item-universe.u-love a:focus figure, .item-universe.u-love a:active figure {
  background-color: #ce0d80;
}

.item-universe.u-epic figure {
  background-color: #deecc6;
}
.item-universe.u-epic a:hover figure, .item-universe.u-epic a:focus figure, .item-universe.u-epic a:active figure {
  background-color: #7cbc0c;
}

.item-universe.u-serious figure {
  background-color: #c6ddec;
}
.item-universe.u-serious a:hover figure, .item-universe.u-serious a:focus figure, .item-universe.u-serious a:active figure {
  background-color: #0294ee;
}

.item-universe.u-sport figure {
  background-color: #c6ece2;
}
.item-universe.u-sport a:hover figure, .item-universe.u-sport a:focus figure, .item-universe.u-sport a:active figure {
  background-color: #0fbc8f;
}

.item-universe.u-dream figure {
  background-color: #d1c6ec;
}
.item-universe.u-dream a:hover figure, .item-universe.u-dream a:focus figure, .item-universe.u-dream a:active figure {
  background-color: #6a3dd8;
}

.item-news {
  margin-bottom: 1.33333em;
  text-align: left;
  font-family: "proxima-nova", Helvetica, Arial, sans-serif;
}
.item-news p, .item-news address {
  font-size: 1.33333em;
  line-height: 1.25em;
}
.item-news a {
  text-decoration: underline;
  white-space: nowrap;
}
.item-news a:hover, .item-news a:focus {
  color: #ea6307;
}
.item-news address {
  display: block;
  font-style: normal;
  color: #747474;
  margin-top: 0.5em;
}

@media only screen and (min-width: 512px) {
  .item {
    width: 21.33333em;
  }
  .item figure {
    width: 13.33333em;
    height: 13.33333em;
  }
}
.home-hero {
  margin-left: -1.33333em;
  margin-right: -1.33333em;
}

.home-badges {
  font-weight: 300;
  font-size: 1.33333em;
  line-height: 1.25em;
  margin-top: 3em;
}
.home-badges a, .home-badges span {
  display: inline-block;
  vertical-align: middle;
}
.home-badges div {
  line-height: 1.75em;
}
.home-badges div a {
  text-indent: -119988px;
  overflow: hidden;
  text-align: left;
  text-transform: capitalize;
}
.home-badges div:before {
  content: " + ";
  margin: 0 0.33333em;
  display: block;
}
.home-badges .mib {
  text-indent: -119988px;
  overflow: hidden;
  text-align: left;
  text-transform: capitalize;
  background-image: url("../img/sprites.png?=1741684382290");
  background-position: -187px 0px;
  background-repeat: no-repeat;
  width: 32px;
  height: 32px;
}
@media only screen and (min-resolution: 2dppx), (-webkit-min-device-pixel-ratio: 2) {
  .home-badges .mib {
    background-image: url("../img/sprites@2x.png?=1741684382290");
    background-position: -186px 0px;
    background-repeat: no-repeat;
    background-size: 218px 212px;
  }
}
.home-badges .pefc {
  background-image: url("../img/sprites.png?=1741684382290");
  background-position: 0px 0px;
  background-repeat: no-repeat;
  width: 86px;
  height: 112px;
}
@media only screen and (min-resolution: 2dppx), (-webkit-min-device-pixel-ratio: 2) {
  .home-badges .pefc {
    background-image: url("../img/sprites@2x.png?=1741684382290");
    background-position: 0px 0px;
    background-repeat: no-repeat;
    background-size: 218px 212px;
  }
}
.home-badges .cov {
  background-image: url("../img/sprites.png?=1741684382290");
  background-position: -88px -50px;
  background-repeat: no-repeat;
  width: 38px;
  height: 48px;
}
@media only screen and (min-resolution: 2dppx), (-webkit-min-device-pixel-ratio: 2) {
  .home-badges .cov {
    background-image: url("../img/sprites@2x.png?=1741684382290");
    background-position: -87.5px -50px;
    background-repeat: no-repeat;
    background-size: 218px 212px;
  }
}
.home-badges .eco-design {
  background-image: url("../img/sprites.png?=1741684382290");
  background-position: -88px 0px;
  background-repeat: no-repeat;
  width: 97px;
  height: 48px;
}
@media only screen and (min-resolution: 2dppx), (-webkit-min-device-pixel-ratio: 2) {
  .home-badges .eco-design {
    background-image: url("../img/sprites@2x.png?=1741684382290");
    background-position: -87.5px 0px;
    background-repeat: no-repeat;
    background-size: 218px 212px;
  }
}

@media only screen and (min-width: 512px) {
  .home-badges div {
    display: inline-block;
  }
  .home-badges div:before {
    display: inline-block;
  }
}
@media only screen and (min-width: 1280px) {
  .home-hero {
    margin: 0;
  }
}
a.fancybox {
  display: block;
}

.fancybox-wrap, .fancybox-skin, .fancybox-outer, .fancybox-inner, .fancybox-image, .fancybox-wrap iframe, .fancybox-wrap object, .fancybox-nav, .fancybox-nav span, .fancybox-tmp {
  padding: 0;
  margin: 0;
  border: 0;
  outline: none;
  vertical-align: top;
}

.fancybox-wrap {
  position: absolute;
  top: 0;
  left: 0;
  bottom: auto;
  z-index: 8020;
}

.fancybox-skin {
  position: relative;
  color: white;
  text-shadow: none;
  background: transparent;
}

.fancybox-opened {
  z-index: 8030;
}

.fancybox-outer, .fancybox-inner {
  position: relative;
}

.fancybox-inner {
  overflow: hidden;
  background: transparent url("../img/alpha.png");
}

.fancybox-type-iframe .fancybox-inner {
  -webkit-overflow-scrolling: touch;
}

.fancybox-error {
  margin: 0;
  padding: 0 1.33333em 5.33333em;
  white-space: nowrap;
}

.fancybox-image, .fancybox-iframe {
  display: block;
  width: 100%;
  height: 100%;
}

.fancybox-image {
  max-width: 100%;
  max-height: 100%;
}

#fancybox-loading {
  position: fixed;
  bottom: 1.33333em;
  left: 50%;
  margin-left: -24px;
  z-index: 8060;
}

#fancybox-loading div {
  width: 48px;
  height: 48px;
  background: url("../img/spinner.gif") center center no-repeat;
}

.fancybox-close {
  background-image: url("../img/sprites.png?=1741684382290");
  background-position: -66px -148px;
  background-repeat: no-repeat;
  width: 24px;
  height: 24px;
  position: fixed;
  top: 6.66667em;
  right: 1.33333em;
  cursor: pointer;
  z-index: 8040;
  -moz-transition: background-color 500ms ease-in-out;
  -o-transition: background-color 500ms ease-in-out;
  -webkit-transition: background-color 500ms ease-in-out;
  transition: background-color 500ms ease-in-out;
  background-color: #b4b4b4;
}
@media only screen and (min-resolution: 2dppx), (-webkit-min-device-pixel-ratio: 2) {
  .fancybox-close {
    background-image: url("../img/sprites@2x.png?=1741684382290");
    background-position: -66px -148px;
    background-repeat: no-repeat;
    background-size: 218px 212px;
  }
}
.fancybox-close:hover {
  background-color: #ea6307;
}

.fancybox-nav {
  position: fixed;
  top: 0;
  width: 40%;
  height: 100%;
  cursor: pointer;
  text-decoration: none;
  background: transparent url("../img/alpha.png");
  -webkit-tap-highlight-color: transparent;
  outline: 0;
  z-index: 8040;
  -moz-transition: opacity 500ms ease-in-out;
  -o-transition: opacity 500ms ease-in-out;
  -webkit-transition: opacity 500ms ease-in-out;
  transition: opacity 500ms ease-in-out;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=40);
  opacity: 0.4;
}
.fancybox-type-ajax .fancybox-nav {
  width: 60px;
}
.fancybox-nav:hover {
  filter: progid:DXImageTransform.Microsoft.Alpha(enabled=false);
  opacity: 1;
}

.fancybox-prev {
  left: 0;
}

.fancybox-next {
  right: 0;
}

.fancybox-nav span {
  position: absolute;
  top: 50%;
  width: 25px;
  height: 48px;
  margin-top: -24px;
  cursor: pointer;
  z-index: 8040;
}

.fancybox-prev span {
  left: 1.33333em;
  text-indent: -119988px;
  overflow: hidden;
  text-align: left;
  text-transform: capitalize;
  background-image: url("../img/sprites.png?=1741684382290");
  background-position: -155px -50px;
  background-repeat: no-repeat;
  width: 25px;
  height: 48px;
}
@media only screen and (min-resolution: 2dppx), (-webkit-min-device-pixel-ratio: 2) {
  .fancybox-prev span {
    background-image: url("../img/sprites@2x.png?=1741684382290");
    background-position: -154px -50px;
    background-repeat: no-repeat;
    background-size: 218px 212px;
  }
}

.fancybox-next span {
  right: 1.33333em;
  text-indent: -119988px;
  overflow: hidden;
  text-align: left;
  text-transform: capitalize;
  background-image: url("../img/sprites.png?=1741684382290");
  background-position: -128px -50px;
  background-repeat: no-repeat;
  width: 25px;
  height: 48px;
}
@media only screen and (min-resolution: 2dppx), (-webkit-min-device-pixel-ratio: 2) {
  .fancybox-next span {
    background-image: url("../img/sprites@2x.png?=1741684382290");
    background-position: -127px -50px;
    background-repeat: no-repeat;
    background-size: 218px 212px;
  }
}

.fancybox-tmp {
  position: absolute;
  top: -99999px;
  left: -99999px;
  visibility: hidden;
  max-width: 99999px;
  max-height: 99999px;
  overflow: visible !important;
}

.fancybox-lock {
  overflow: hidden;
}

.fancybox-overlay {
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  display: none;
  z-index: 8010;
  background: white;
}

.fancybox-overlay-fixed {
  position: fixed;
  bottom: 0;
  right: 0;
}

.fancybox-lock .fancybox-overlay {
  overflow: auto;
  overflow-y: scroll;
}

.fancybox-title {
  visibility: hidden;
  position: relative;
  text-shadow: none;
  z-index: 8050;
}

.fancybox-opened .fancybox-title {
  visibility: visible;
}

.fancybox-title-float-wrap {
  position: absolute;
  bottom: 0;
  right: 50%;
  margin-bottom: -35px;
  z-index: 8050;
  text-align: center;
}

.fancybox-title-float-wrap .child {
  display: inline-block;
  margin-right: -100%;
  padding: 2px 20px;
  background: transparent;
  background: rgba(0, 0, 0, 0.8);
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  border-radius: 15px;
  text-shadow: 0 1px 2px #222;
  color: #FFF;
  font-weight: 600;
  line-height: 24px;
  white-space: nowrap;
}

.fancybox-title-outside-wrap {
  position: relative;
  margin-top: 10px;
  color: #fff;
}

.fancybox-title-inside-wrap {
  position: absolute;
  left: 0;
  bottom: -4em;
  width: 100%;
  padding: 0.66667em 1.33333em;
  text-align: center;
  color: #5a5a5a;
  min-height: 4em;
}
.fancybox-title-inside-wrap h4, .fancybox-title-inside-wrap p {
  display: inline;
  font-size: 1.16667em;
  line-height: 1.14286em;
}
.fancybox-title-inside-wrap h4 {
  color: black;
  font-weight: 500;
}
.fancybox-title-inside-wrap p {
  margin-left: 0.33333em;
}

.fancybox-title-over-wrap {
  position: absolute;
  bottom: 0;
  left: 0;
  color: #fff;
  padding: 10px;
  background: #000;
  background: rgba(0, 0, 0, 0.8);
}

.hero-interview .banner article:hover .bgd-fig {
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=75);
  opacity: 0.75;
}

.interview.article {
  padding-bottom: 0;
}
.interview div:before, .interview div:after {
  content: " ";
  display: table;
}
.interview div:after {
  clear: both;
}
.interview h4 {
  text-align: left;
}

.three-blocs figure, .three-blocs .text {
  width: 100%;
}

.bg-800 {
  width: auto;
  height: 250px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: left;
  border-bottom: 1.33333em white solid;
}

.bg-400 {
  width: auto;
  height: 250px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: left;
  border-bottom: 1.33333em white solid;
}

@media only screen and (min-width: 512px) {
  .interview.article {
    padding-bottom: 2.66667em;
  }
}
@media only screen and (min-width: 768px) {
  .interview {
    position: relative;
  }
  .interview.article {
    padding-bottom: 1.33333em;
  }

  .three-blocs {
    padding: 0.66667em 0;
    margin-left: -0.66667em;
    margin-right: -0.66667em;
  }
  .three-blocs:before, .three-blocs:after {
    content: " ";
    display: table;
  }
  .three-blocs:after {
    clear: both;
  }
  .three-blocs figure {
    width: 50%;
  }
  .three-blocs figure, .three-blocs .text {
    float: left;
    padding: 0 0.66667em;
  }

  .bg-400, .bg-800 {
    width: 100%;
    border-left: 0.66667em white solid;
    border-right: 0.66667em white solid;
    background-position: center;
  }

  .bg-400 {
    height: 600px;
  }

  .bg-800 {
    height: 1200px;
  }
}
@media only screen and (min-width: 1024px) {
  .bg-400 {
    height: 400px;
  }

  .bg-800 {
    height: 800px;
  }
}
.cc-window {
  display: block;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  min-height: 4em;
  background: #80715c;
  color: white;
  padding: 1.66667em 1.33333em;
  opacity: 1;
  z-index: 1000;
  visibility: visible;
  transition: opacity 0.25s linear, visibility 0.25s linear, z-index 0.25s linear;
}
.cc-window.cc-invisible {
  opacity: 0;
  z-index: -1;
  visibility: hidden;
}

.cc-message {
  display: block;
  font-size: 1.16667em;
  line-height: 1.42857em;
}
.cc-message a {
  text-decoration: underline;
  white-space: nowrap;
}
.cc-message a:hover, .cc-message a:focus {
  text-decoration: none;
}

.cc-compliance {
  margin-top: 1.33333em;
}

.cc-dismiss {
  display: block;
  padding: 4px 16px;
  background-color: #ea6307;
  color: white;
  border: 2px solid #ea6307;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  text-align: center;
  width: 100%;
  font-size: 1.16667em;
  line-height: 1.42857em;
  cursor: pointer;
}
.cc-dismiss:hover, .cc-dismiss:focus {
  background-color: white;
  color: #ea6307;
  text-decoration: none;
}

@media only screen and (min-width: 512px) {
  .cc-window {
    padding-right: 6.66667em;
  }

  .cc-compliance {
    position: absolute;
    top: 50%;
    right: 1.33333em;
    margin-top: -1.33333em;
  }

  .cc-dismiss {
    width: auto;
  }
}
.print-header {
  display: none;
}

@media print {
  *, *:before, *:after {
    background: transparent !important;
    color: #000 !important;
    box-shadow: none !important;
    text-shadow: none !important;
  }

  a, a:visited {
    text-decoration: none;
  }

  a[target="_blank"]:after {
    content: " (" attr(href) ")";
    font-size: 90%;
  }

  abbr[title]:after {
    content: " (" attr(title) ")";
  }

  thead {
    display: table-header-group;
  }

  tr, img, figure, blockquote, li, .item, .news, .aside {
    page-break-inside: avoid;
  }

  @page {
    margin: 0.5cm;
  }
  p, h2, h3 {
    orphans: 3;
    widows: 3;
  }

  h1, h2, h3, h4 {
    page-break-after: avoid;
  }

  .header, .cycle-pager, .read-more, .pagination, .navigation, .share, .footer {
    display: none !important;
  }

  .print-header, .more-text {
    display: block !important;
  }
}

/*# sourceMappingURL=main.css.map */
